<!-- 设备监控 -->

<template>
	<div class="monit-equip main-cnt">
		<div class="content">
			<common-table ref="chargeTable" tableHeight="calc(100vh - 325px)" :ischeck="false" :ispaging="true"
				:apiName="PowerStations.getConnectorLists" :filters="filters" :columns="tableColumns">
				<template #custom>
					<div class="flex">
						<div class="title-all s-p-t">
							<span class="s-m-r">全部充电枪</span>
							<span class="title-num-green f-s-21">{{ totalQuantity }}</span>
						</div>
						<div class="s-p-t xl-m-l-r f-s-14 flex cursor-pointer" style="line-height: 28px;"
							v-for="(item, i) in statisticsData" :key="i" @click="onClickStatus(item)">
							<div>
								<img :src="item.icon" class="equip-img" />
							</div>
							<div>
								<span class="title-text">{{ item.status_text }}</span>
								<span :class="item.statusColor">{{ item.count }}</span>
							</div>
						</div>
					</div>
				</template>

				<template #status_text="{ row }">
					<div class="flex" :class="setColor(row.c_status).statusColor">
						{{row.status_text}}
					</div>
				</template>

			</common-table>
			<div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		ref,
		onMounted,
		computed,
		watch
	} from "vue";
	import {
		PowerStations
	} from "@/plugins/api.js";
	import {
		useStore
	} from "vuex";
	import {
		ElMessage
	} from "element-plus";
	import offline from '@/assets/img/offline.png';
	import idle from '@/assets/img/idle.png';
	import occupy from '@/assets/img/occupy.png';
	import charging from '@/assets/img/charging.png';
	import reservation from '@/assets/img/reservation.png';
	import fault from '@/assets/img/fault.png';

	const store = useStore();
	const menuTokens = computed(() => store.state.menuToken.menuTokens);
	const authData = ref([]);
	watch(
		() => menuTokens.value,
		(data) => {
			if (data.length) {
				authData.value = data;
			}
		}, {
			deep: true,
			immediate: true,
		}
	);
	/** 表格对象 */
	const chargeTable = ref(null);
	const statisticsData = ref([]); // 统计数据
	const totalQuantity = ref(0); // 总数
	/** 筛选条件列表 */
	const filters = ref([{
			name: "keywords",
			filterType: "search",
			value: "",
			placeholder: "请输入充电桩名称、编号",
		},
		{
			value: "",
			name: "c_status"
		}
	]);
	/** 表格配置数据 */
	const tableColumns = ref([{
			prop: "c_name",
			label: "充电枪名称",
			color: "--text-color",
		},
		{
			prop: "status_text",
			label: "充电枪状态",
			type: 'customRender',

		},
		//  {
		//    prop: "status_text",
		//    label: "充电枪状态",
		//    type: "option",
		//    // metaData: ["空闲", "充电中", "充完占用", "故障", "离线", "预约占用"],
		//    metaData: ["空闲", "占用（充电中）", "占用（未充电）", "故障", "离网", "占用（预约锁定）"],
		//    colorData: [
		//      "var(--theme-color)",
		//      "var(--text-blue-color)",
		//      "var(--warning-color)",
		//      "var(--error-color)",
		//      "var(--text-gray-color)",
		//      "var(--text-roseo-color)",
		//    ],

		//  },
		{
			prop: "s_name",
			label: "归属电站",
			color: "--text-color",
		},
		{
			prop: "e_name",
			label: "充电桩名称",
			color: "--text-color",
		},
		{
			prop: "c_power",
			label: "充电枪功率(Kw)",
			color: "--text-color",
		}
	]);
	const setColor = (status) => {
		let data = {
			 statusColor :'',
			 icon : ''
		}
		if (status == '0') {
			// 离网
			data.statusColor = 'title-num-gray';
			data.icon = offline;
		} else if (status == '1') {
			// 空闲
			data.statusColor = 'title-num-green';
			data.icon = idle;
		} else if (status == '2') {
			// 占用（未充电）
			data.statusColor = 'title-num-orange';
			data.icon = occupy;
		} else if (status == '3') {
			// 占用（充电中）
			data.statusColor = 'title-num-blue';
			data.icon = charging;
		} else if (status == '4') {
			// 占用（预约锁定）
			data.statusColor = 'title-num-roseo';
			data.icon = reservation;
		} else if (status == '255') {
			// 故障
			data.statusColor = 'title-num-red';
			data.icon = fault;
		}
		return data
	}
	const getConnectorCount = () => {
		PowerStations.getConnectorCount().then((res) => {
			if (res.Code === 200) {
				statisticsData.value = res.Data;
				statisticsData.value.forEach(item => {
					// 总数
					totalQuantity.value += item.count;
					item.statusColor  = setColor(item.status).statusColor;
					item.icon = setColor(item.status).icon;
					// 显示颜色
				})
			} else {
				ElMessage.error(res.Message);
			}
		});
	}
	const onClickStatus = (row) => {
		filters.value[1].value = row.status
		chargeTable.value.tableLoad();
	}

	onMounted(() => {
		getConnectorCount();
		chargeTable.value.tableLoad();
	});
</script>

<style lang="scss">
	.monit-equip {
		font-family: "Source Han Sans CN";

		.content {
			padding: 20px;
		}
	}

	.xl-m-l-r {
		margin: 0 20px;
	}

	.f-s-21 {
		font-size: 21px;
	}

	.f-s-14 {
		font-size: 14px;
	}

	.title-all {
		font-size: 18px;
		font-weight: 600;
	}

	.equip-img {
		width: 28px;
		height: 28px;
	}



	.title-text {
		color: var(--text-second-color);
		margin: 0 5px;
	}
</style>